@import '../variables';

.primary-color {
    color: $primary-brand-color !important;
}

.bg-primary-brand {
    background-color: $primary-background-color;
}

.bg-secondary-brand {
    background-color: $secondary-color !important;
}

.text-black {
    color: black;
}

.bd-bottom {
    border-bottom: 1px solid rgb(65, 65, 65);
}

.bd-top {
    border-top: 1px solid rgb(65, 65, 65);    
}

.icon-size {
    width: $default-icon-size !important;
    height: $default-icon-size !important;
    color: white;
}

.icon-lg {
    width: 45px;
    height: 45px;
    color: white;
}

.icon-md {
    width: 35px;
    height: 35px;
}

.icon-sm {
    width: 25px;
    height: 25px;
    color: white;    
}

.clear-icon {
    background-color: transparent;
    border: 0;
}

.gear-icon {
    display: flex;
    margin-top: 6px;
}

.bottom-bar-shadow {
    border-top: 1px solid $border-color;
    box-shadow: 0px -2px 15px 0px rgba(97, 97, 97, 0.671)

}


//buttons

.btn-large, .transparent-btn-large {
    width: 100%;
    font-size: 1.4rem !important;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-large {
    background-color: $primary-brand-color;
    color: $secondary-color !important;
}

.transparent-btn-large {
    background-color: transparent;
    border: 3px solid $primary-brand-color;
    color: $primary-brand-color;
}

// for the contact card make the icons have graceful transitions
.contact-icon-container {
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }
  
  .visible {
    opacity: 1;
    visibility: visible;
  }
  
  .hidden {
    opacity: 0;
    visibility: hidden;
  }


//bootstrap adjustments

.btn-outline-primary {
    border-color: $primary-brand-color;
    color: $primary-brand-color;
    border-width: 2px;
    font-size: 1.2rem !important;
    width: 50%;
    padding: 10px 0 10px 0;
}

 .btn-primary {
    background-color: $primary-brand-color;
    color: black;
    border: 0;
}  

.btn {
    font-weight: 900;
    font-size: 1.1rem;
}

.btn-biz-card {
    background-color: transparent;
    border: solid 1px white;
    color: white;
    font-size: 1rem !important;
    max-height: 40px;
    padding-inline: 10px;
    border-radius: 5px;
}  

body .btn:active, .btn:focus  {
    background-color: $primary-brand-color !important;
    border-color: $primary-brand-color !important;
    color:black !important;
}




//defaults


p {
    color: white;
}

.modal {
    background-color: rgba(3, 3, 3, 0.678);

    .modal-content {
        background-color: $primary-background-color;
        border: solid 1px #6bd9d93d;
        padding: 5px;
    }

    .modal-dialog {
        width:85%;
        left: 5%;
        top: 0%;
    }

    .modal-body {
        padding: 0;
    }
    
    .profile-page {
        height: auto;
    }
}

// home page

.home-page {
    max-height: 100vh !important;

    .skanz-home-logo {
        max-height: 10vh;
    }

    .skanz-hero-img {
        max-height: 30vh;
    }
}

.gen-pfp {
    /* Set a fixed width and height for the circle */
    width: 100px;  // You can adjust the size as needed
    height: 100px; // Make sure width and height are equal for a perfect circle

    /* Make the image a circle */
    border-radius: 50%;

    /* Ensure the image covers the area and is centered, regardless of its aspect ratio */
    object-fit: cover;

    /* Optional: Add a border if desired */
    border: 2px solid white; // Adjust color and size as needed
}