/* Style for Login Button */
.login-btn {
    border: 2px solid #00E6E6;
    background-color: transparent;
    transition: all 0.3s ease;
  }
  
  .login-btn:hover {
    box-shadow: 0 0 20px #00E6E6 !important;
    background-color: transparent !important;
    border-color: #00E6E6 !important;  
}
  
  /* Style for Signup Button */
  .signup-btn {
    border: 2px solid #00E6E6;
    background-color: #00E6E6;
    color: white;
    transition: all 0.3s ease;
  }
  
  .signup-btn:hover {
    box-shadow: 0 0 20px #00E6E6 !important;
    background-color: #00E6E6 !important;
    border-color: transparent !important;
  }

  