.skanz-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-container {
  /* center in the middle */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #6c63ff;

  /* limit the width to fit in the middle of screen and full on mobile */
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.profile-container-organization {
    /* center in the middle */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #6c63ff;
  
    /* limit the width to fit in the middle of screen and full on mobile */
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

.container-premium {
  height: 100vh;
  background-color: #f5f5f5;
  justify-content: start;
}

.profile-header {
  /* purple background */
  background-color: #6c63ff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}

.profile-header-premium {
  justify-content: start;
  background-color: #f5f5f5;
  justify-content: space-around;
  padding: 20px 0;
  margin-top: 20px;
}

.profile-picture {
  position: relative;
  top: 80px;
}

.profile-picture img {
  aspect-ratio: 1/1;
  max-width: 250px;
  border-radius: 5%;
}

.profile-picture-circle {
  position: relative;
  top: 100px;
}

.profile-picture-circle img {
  aspect-ratio: 1/1;
  max-width: 250px;
  border-radius: 50%;
}

.picture-premium {
  position: static;
}

.picture-premium img {
  max-width: 230px;
}

.image-container {
  max-height: 400px;
}

.profile-header-organization {
  max-height: 400px;
  object-fit: scale-down;
  /* image cover all background */
}

.profile-body {
  /* background white but not too white for the eyes */
  padding-top: 80px;
  width: 100%;
  background-color: #f5f5f5;

  /* square border on top left and right corner */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: 110px;

}


.profile-body > div:first-of-type {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-padding {
  padding-top: 0px;
}

.profile-body-organization {
  /* background white but not too white for the eyes */
  width: 100%;
  background-color: #f5f5f5;

  /* square border on top left and right corner */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding-bottom: 110px;
}
.profile-name {
  /* center the name */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 0px;

  color: rgb(42, 42, 42);
  font-size: 22px;
  font-weight: 700;
}

.profile-title {
  /* center the title */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: rgb(93, 93, 93);
  font-size: 16px;
  font-weight: 300;
}

.profile-title > p {
  margin-bottom: 0px;
}


.profile-email {
  /* center the email */
  display: flex;
  align-items: center;
  gap: 5px;
  

  color: rgb(42, 42, 42);
  font-size: 16px;
  font-weight: 300;
}

.profile-email a {
  color: rgb(42, 42, 42);
  font-size: 16px;
  font-weight: 300;
}

.profile-contact {
  display: flex;
    flex-direction: column;
}

.profile-contact-item {
  display: flex;
  justify-content: felx-start;
  align-items: center;
  gap: 5px;

  color:rgb(42, 42, 42)
}

.profile-contact-item-icon-more button {
  /* center the button */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 15px;
  height: 30px;

  /* no border */
  border: none;
  background-color: transparent;
  color: rgb(93, 93, 93);
  font-size: 16px;
  font-weight: 300;
}

.show-more-btn {
    /* center the button */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 15px;
    height: 30px;
  
    /* no border */
    border: none;
    background-color: transparent;
    color: rgb(93, 93, 93);
    font-size: 16px;
    font-weight: 300;
}
.profile-contact-button {
  /* center the button */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  max-width: 300px;
  /* no border */
  border: none;
  background-color: #6c63ff;
  color: white;
  font-size: 16px;
  font-weight: 300;
  padding: 10px 20px;
  border-radius: 5px;
}

.profile-skanz {
  position:fixed;
  bottom: 1px;
  width: 100%;
  padding-bottom: 20px;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* white background with 80% opacity */
  background-color: rgba(245, 245, 245, 0.8);
}

.profile-skanz-button {
    /* center the button */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
    /* no border */
    border: none;
    background-color: #6c63ff;
    color: white;
    font-size: 16px;
    font-weight: 300;
    padding: 10px 20px;
    border-radius: 5px;
}

.profile-skanz-button  p{
  margin-bottom: 0;
}

.profile-contact-button:hover {
  background-color: #5b53e0;
}

.profile-about {
  margin-top: 35px;
  padding: 0px 20px;
  padding-bottom: 20px;
  color: rgb(22, 22, 22);
  background-color: #e6e6e6;
  border-radius: 5px;
  /* small gray shadow */
  width: 80%;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.profile-about-title {
  /* center the title */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 0px;

  color: rgb(42, 42, 42);
  font-size: 18px;
  font-weight: 600;
}

.profile-about-content {
  /* center the content */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 0px;

  color: rgb(42, 42, 42);
  font-size: 16px;
  font-weight: 300;
}

.premium-text {
  justify-content: start;
  align-items: flex-start;
  text-align: start;
}

.profile-socials {
  /* center the socials */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  /* small gap between socials */
  gap: 20px;

  margin-top: 35px;
  margin-left: 20px;
  margin-right: 20px;
}

.profile-socials-organization {
  /* center the socials */
  display: flex;
  justify-content: center;
  align-items: center;

  /* small gap between socials */
  gap: 20px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.profile-socials-premium {
  /* center the socials */
  display: flex;
  justify-content: center;
  align-items: center;

  /* small gap between socials */
  gap: 20px;

  margin-top: 0px;
  margin-bottom: -15px;
}

.profile-socials a{
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.profile-socials a img {
  width: 30px;
  height: 30px;
}

.profile-links {
  width: 80%;
}

.profile-link-item {
  width: 100%;
  border-radius: 8px;
  background-color: #e6e6e6;
  padding: 10px;
}

.profile-link-item-icon {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.profile-link-item-icon img {
  height: 30px;
  width: 30px;
}

.profile-link-data {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-left: 15px;

  color: rgb(22, 22, 22);
  font-weight: 600;
}

.profile-link-data > div {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.profile-link-data :last-child {
  color: rgb(80, 80, 80);
}

.profile-title-premium {
  font-size: 14px;
  margin-top: -8px;
}

/* reduze max-width of img on mobile */
@media only screen and (max-width: 600px) {
  .picture-premium img {
    max-width: 150px;
  }
  .profile-contact-button  {
    max-width: 200px;
    font-size: 14px;
    max-height: 40px;
    margin-top: 5px;
  }
  .profile-name-premium {
    font-size: 16px;
  }
  .profile-title-premium {
    font-size: 11px;
    margin-top: -8px;
  }
  .profile-email-premium a {
    font-size: 11px;
  }
  .profile-email-premium {
    display: flex;
    align-items: center;
    font-size: 11px;
  }
  .profile-contact-item-premium {
    font-size: 11px;
  }
  .profile-contact-item-icon-more button {
    font-size: 11px;
  }
}
