#home {
    display: block;
    width: 100%;
    margin-top: 88px;
}

.content {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
}

.wave-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.heading-home {
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-home {
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 3px;
    text-shadow: 0px 3px 9px rgb(0 0 0 / 45%);
    position: relative;
    width: 50%;
    min-width: 600px;
}

.transition-text {
    position: absolute!important;
    left: 33%!important;
    top: 69px!important;
    width: 100%!important;
    color: #00F0F0;
}

.heading-img {
    display: block;
    width: 50%;
}

.section-1 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 120px;
}

.section-1 .box-img, .section-1 .box-info {
    display: block;
    width: 50%;
}

.section-1 .box-img img {
    display: block;
    width: 100%;
}

.section-1 .box-info {
    position: relative;
    margin-left: 30px;
}

.section-1 .box-info:before {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;
    top: 0;
    border-top: 3px solid #00F0F0;
    border-left: 3px solid #00F0F0;
}

.section-title {
    width: 100%;
    text-align: left;
    font-size: 28px;
    font-weight: 700;
    color: #FFF;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 10px;
}

.ul-info li {
    list-style: none;
    font-size: 20px;
    color: #fff;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.ul-info li:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #00F0F0;
    margin-right: 10px;
}

.section-2, .section-4 {
    display: block;
    margin-top: 100px;
    margin-bottom: 75px;
}

.section-2 .section-title, .section-4 .section-title {
    display: block;
    text-align: center;
    font-size: 40px;
}

.section-2 .section-title:after, .section-4 .section-title:after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background: #00F0F0;
    max-width: 200px;
    margin: 0 auto;
}

.items-section {
    display: flex;
    justify-content: center;
}

.item-section {
    display: block;
    border-radius: 8px;
    background: #1f1f1f;
    padding: 15px;
    position: relative;
    margin-right: 75px;
    max-width: 220px;
    width: 100%;
    transition: all 0.3s;
}

.item-section:hover {
    box-shadow: 0px 0px 10px 0px #00f0f0;
}

.item-section:last-of-type {
    margin-right: 0;
}

.item-icon {
    color: #00F0F0;
    margin: 0 auto;
    display: block!important;
    width: 50px!important;
    font-size: 50px!important;
}

.card-title {
    font-size: 22px;
    width: 100%;
    display: block;
    margin-top: 15px;
    text-align: center;
}

.item-text {
    display: block;
    width: 100%;
    color: #b5b5b5;
    font-size: 18px;
    text-align: center;
}

.icon-arrow {
    position: absolute;
    right: -60px;
    top: 50%;
    color: #00f0f0;
    transform: translateY(-50%);
    font-size: 50px!important;
}

.item-network {
    margin-top: 0px;
}

.item-network .icon-arrow {
    top: auto;
    bottom: -30px;
}

.btn-signup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 56px;
    background: #00F0F0;
    color: #1f1f1f;
    font-weight: 800;
    border-radius: 8px;
    padding: 10px;
    font-size: 18px;
    max-width: 250px;
    transition: all 0.3s;
}

.second {
    margin: 0 auto;
}

.btn-signup:hover {
    color: #1f1f1f;
    box-shadow: 0px 0px 10px 0px #00f0f0;
}

.button-icon {
    margin-left: 15px;
}

.section-3 {
    display: block;
    margin-bottom: 75px;
}

.benefit-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.benefit-item .box-img, .benefit-item .box-content {
    display: block;
    width: 50%;
}

.benefit-item .box-content {
    margin-left: 30px;
}

.benefit-item .box-img img {
    width: 100%;
}

.benefit-title {
    display: block;
    width: 100%;
    font-size: 28px;
    color: #FFF;
    font-weight: 700;
    margin-bottom: 30px;
    position: relative;
    padding-left: 15px;
}

.benefit-title:before {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 0;
    top: 0;
    border-top: 3px solid #00F0F0;
    border-left: 3px solid #00F0F0;
}

.benefit-text {
    display: block;
    width: 100%;
    color: #fff;
    font-size: 18px;
}

.use-items {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.item-use {
    display: block;
    width: 100%;
    max-width: 300px;
    text-align: center;
    margin-right: 30px;
    height: 250px;
    margin-bottom: 30px;
    transition: all 0.3s;
    padding: 15px;
    border-radius: 8px;
}

.item-use:hover {
    box-shadow: 0px 0px 10px 0px #00f0f0;
}

.item-use:hover .title-use:after {
    width: 100%;
}

.item-use:last-of-type {
    margin-right: 0;
}

.box-icon {
    display: flex;
    width: 100%;
    max-width: 60px;
    margin: 0 auto;
    margin-bottom: 20px;
    background: #1f1f1f;
    border-radius: 8px;
    height: 60px;
    justify-content: center;
    align-items: center;
}

.use-icon {
    font-size: 45px!important;
    display: block!important;
    margin: 0 auto!important;
    color: #FFF!important;
}

.title-use {
    font-weight: 700;
    font-size: 22px;
    color: #fff;
    padding-bottom: 15px;
    margin: 0;
}

.title-use:after {
    content: '';
    display: block;
    width: 0;
    max-width: 50px;
    height: 2px;
    background: #00f0f0;
    transition: all 0.8s;
    margin: 0 auto;
    margin-top: 5px;
}

.text-use {
    color: #b5b5b5;
    font-size: 18px;
    padding-top: 10px;
}

@media(max-width: 1320px) {
    #home {
        padding: 0px 50px;
    }
}

@media(max-width: 1110px) {
    .heading-img {
        display: none;
    }
    .section-title {
        font-size: 28px;
    }
    .section-title::before {
        display: none;
    }
}

@media(max-width: 990px) {
    .items-section, .use-items {
        flex-wrap: wrap;
    }
    .item-section {
        max-width: 100%;
        margin-top: 24px;
        margin-right: 0;
    }
    .icon-arrow {
        display: none!important;
    }
    .item-use {
        max-width: 100%;
        margin-right: 0;
    }
}

@media(max-width: 767px) {
    .title-home {
        width: 100%;
        max-width: 382px;
        min-width: auto;
    }
    .transition-text {
        left: 20%!important;
        top: 135px!important;
    }
    .section-1 {
        flex-wrap: wrap;
    }
    .section-1 .box-img {
        width: 100%;
    }
    .section-1 .box-info {
        width: 100%;
        margin-left: 0;
        margin-top: 50px;
    }
    .item-section {
        margin-right: 0;
    }
    .benefit-item {
        flex-wrap: wrap;
        margin-top: 0;
    }
    .benefit-item .box-img, .benefit-item .box-content {
        width: 100%;
        margin-top: 70px;
    }
    .benefit-item .box-img {
        order: 2;
    }
    .benefit-item .box-content {
        order: 1;
    }
}

@media(max-width: 480px) {
    .transition-text {
        top: auto!important;
        bottom: -55px!important;
        left: 0!important;
    }
}