* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif;
}

body {
    background-color: black;
}



#header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 999;
    padding: 20px 0;
    transition: all 0.3s;
}

.scroll {
    padding: 15px 0!important;
    background: #1f1f1f!important;
}

.nav {
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 1320px;
}

.nav-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 1320px;
}

.nav-footer{
    max-width: 100px;
    margin: 0;
    justify-content: flex-start;
}

.nav-footer .nav-link:first-of-type{
    padding-left: 0;
}

.menuButton {
    display: none!important;
}

.box-logo {
    display: block;
    width: 100%;
    max-width: 180px;
}

.box-logo img {
    display: block;
    outline: 0;
    width: 100%;
}

.nav-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.nav-items .item {
    display: block;
    outline: 0;
    color: #FFF;
    transition: all 0.3s;
    margin-right: 24px;
    font-weight: 700;
}

.nav-items .item:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #FFF;
    ;
    transition: all 0.3s;
}

.nav-items .item:hover:after {
    width: 100%;
}

.nav-items .item-login {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1f1f1f;
    border-radius: 4px;
    padding: 0px 10px;
    color: #00F0F0;
    font-weight: 700;
}

.nav-items .item-login:hover {
    opacity: 0.8;
}

.nav-items .item-login:hover:after {
    display: none;
}

.scroll .nav-items .item {
    color: #FFF;
}

.scroll .nav-items .item:hover {
    color: #00F0F0;
}

.scroll .nav-items .item-login:hover {
    color: #1f1f1f;
    opacity: 0.8;
}

.scroll .nav-items .item:after {
    background: #00F0F0;
}

.scroll .nav-items .item:hover:after {
    width: 100%;
}

.scroll .nav-items .item-login {
    background: #00F0F0;
    color: #1f1f1f;
}

.menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1f1f1f;
    transition: all 0.3s;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
    border-radius: 0px 0px 50px 0px;
    padding: 24px;
}

.menu-mobile .box-logo {
    justify-content: flex-start;
}

.header-menu {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.icon-close {
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: block;
}

.itens-menu {
    display: block;
    padding: 40px 24px 40px 0px;
}

.itens-menu li {
    list-style: none;
    width: 100%;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    padding: 10px 0px;
}

.itens-menu .item {
    color: #FFF;
    font-weight: 600;
    font-size: 18px;
}

.active {
    opacity: 1;
    visibility: visible;
}

@media(max-width:1366px) {}

@media(max-width: 767px) {
    #header {
        padding: 15px;
        background: #1f1f1f;
    }
    .scroll {
        padding: 10px 15px!important;
    }
    .menuButton {
        display: block!important;
        cursor: pointer;
        outline: 0;
    }
    .box-logo {
        display: flex;
        justify-content: center;
        max-width: calc(100% - 24px);
    }
    .box-logo img {
        width: 180px;
    }
    .nav-items {
        display: none;
    }
}

.card {
    background-color: rgb(27, 27, 27);
    border-color: transparent;
}