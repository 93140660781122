/* styles.css */

.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000000aa;
    top: 65px;
    left: 0;
    z-index: 1000000;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  
  .menu {
    width: 100vw;
    padding: 20px;
    background-color: #1f1f1f;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }

/* Profile, Dashboard, Settings, Sign Out */
.link {
  color: #c7c7c7;
}

.link span {
  margin-left: 5px;
}

/* Navbar */
.navbar {
  position: relative;
}

.navbar-brand {
  order: 1;
}

.navbar-toggler {
  order: 3;
}

.navbar-nav {
  margin-right: -50px;
  margin-top: 50px;
}

.navbar-nav .dropdown-menu {
  right: 0;
  left: auto;
  padding: 0.75rem;
}

.dropdown-header {
  padding-left: 0 !important;
  margin-bottom: 0.5rem;
  color: #ffc107;
}

.dropdown-item {
  color: #c7c7c7;
  cursor: pointer;
}

.dropdown-divider {
  margin: 0.5rem 0;
  border-color: rgba(255, 255, 255, 0.15);
}

.avatar {
  cursor: pointer;
}

.avatar img {
  object-fit: contain;
  height: 40px;
  width: 40px;
  background-color: #eee;
}



/* Return Link */
.nav.active {
  display: flex;
  align-items: center;
  color: #000;
}

.nav.active .feather-icon {
  margin-right: 0.75rem;
}

/* Nav Tabs */
.nav-tabs .nav-link {
  color: #000;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #01CDCC;
  color: #ffffff !important;
}

.nav-tabs.overflow-x {
  overflow-x: auto;
  white-space: nowrap;
}

/* Business Dashboard */
.nav-item:last-child .nav-link {
  margin-right: 0;
}

.nav-link:hover {
  color: rgb(162, 240, 253) !important;
}

.nav-link {
  color: rgb(202, 202, 202) !important;
}


.upgrade {
  padding: 7px 30px !important;
  background-color: #01CDCC;
  color: #ffffff !important;
  font-weight: 800;
  border-radius: 0.375rem !important;
  transition: background-color 0.3s;
}

.upgrade:hover {
  color: white !important;
  background-color: #00d9ff;
  box-shadow: 0 0 10px #00e1ff;
}


/*
==========
cart.js
==========
*/

.price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.price-container-mobile {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.price-container-desktop {
  margin-left: auto;
  margin-right: 25px;
}

.price-button {
  height: 40px;
  width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: bold;
  background-color: #000;
  margin: 0 8px;
  border-radius: 8px;
  border: 2px solid #555;
}

.price-amount-mobile {
  margin: 20px;
}


.qr-code-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
}

.qr-code-image {
  background-color: #FFF;
  border-radius: 5px;
  padding: 6px;
  padding-bottom: 0px;
}

.qr-code-image > svg {
  border-radius: 5px;
  margin: 5px 5px -4px 5px;
}

.price-info {
  margin-left: auto;
}

.price-info-label {
  font-weight: bold;
}

.total-info {
  margin-left: 5%;
  margin-right: 5%;
}

.total-info-label {
  font-weight: bold;
}

.price-component-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 10px 10px 10px;
}

.pricing-card > .card {
  background-color: white !important;
}
.nav .active {
  color: #dbdbdb;
}

.pay-container {
  cursor: pointer;
}

.pay-container .pay-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.pay-container .pay-content span {
  font-weight: bold;
  font-size: 20px;
}

.pay-container .pay-content img {
  height: 40px;
  margin-left: 10px;
}

.pricing-title {
  font-size: 1.6rem;
  color: #60c5ff;
  text-shadow: 0 0 10px rgba(0, 162, 255, 0.5);
  font-weight: bold;

}

.bg-pricing {
  border-bottom: 1px solid #00a2ff ;
}

.price-number {
  font-size: 2rem;
}

.btn-toggle {
  background-color: #353535;
  color: white;
}

.btn-toggle.active {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-price {
  padding: 5px 10px 5px 10px;
}

/* Add a CSS class to hide the 'Quests' tab */
.hide-quests {
  display: none;
}