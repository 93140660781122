.custom-progress {
    width: 50%;
    height: 30px;
  }
  
  .custom-progress .progress-bar {
    background-color: rgb(0, 182, 82);
  }

  .greyed-out {
    opacity: 0.4;
    box-shadow: 0 0 0px !important;
  }

  .glow-green {
    box-shadow: 0 0 5px #00ff00, 0 0 6px #00ff00, 0 0 12px #00ff00, 0 0 16px #00ff00;
  }
  
  .claim-reward {
    cursor: pointer;
  }

  .animated-image {
    box-shadow: 0 0 10px #448b62, 0 0 20px #448b62, 0 0 30px #448b62, 0 0 40px #448b62;
    animation: glow 2s ease-in-out infinite;
  }

  .event-btn {
    border: 2px solid #01CDCC;
    padding: 5px 20px;    
    transition: all 0.8s ease;
    border-radius: 0.375rem;
  }
  
  .event-btn:hover {
    box-shadow: 0 0 20px #00E6E6 !important;
    border-color: transparent !important;
    color: white;
  }

  
  @media (max-width: 900px) {
    .small-font {
      font-size: 19px;
      padding: 0px;
    }
  }

  @keyframes glow {
    0%, 100% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(1.05);
    }
  }
