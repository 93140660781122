@import '../variables';
@import '../utility/mixins.scss';


.events {
    
    .event-card {
        color: rgb(151, 106, 106);
        height: 20vh;
        background-size: cover;
        background-position: center;
        position: relative;
        
      }
    
    .bg-overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.555);
    }
}