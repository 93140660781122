.custom-card {
  transition: top 0.2s;
  top: 0px;
}

.custom-card:hover {
  border: 1px solid #ffffff;
  cursor: pointer;
  top: -3px;
}
