@import '../variables';
@import '../utility/mixins.scss';

.profile-page {

  overflow: scroll;
  // profile edit 


  .profile-edit {
    color: $primary-brand-color;
    position: absolute;
    left: 200px;
    z-index: 200;
    top: 150px;
    width: 30px;
    height: 30px;
    background-color: black;
    border-radius: 50%;
    padding: 4px;
  }

  .btn-edit-photo {
    position: absolute;
    top: 145px;
    right: 105px;
    z-index: 200;
    border-radius: 21px;
    padding-right: 5px;
    padding-left: 4px;
    width: 40px !important;
    height: 40px !important;
    color: black;
    background-color: $primary-brand-color !important;
  }

  .profile-spinner {
    height: 100px;
    width: 100px;
  }
  
  p {
    line-height: normal;
    margin: 0;
    font-size: small;
    color:rgb(204, 204, 204);
  }
  .profile-edit-icon {
    position: absolute;
    right: 10px;
  }

  .social-vis-icon {
    width: 23px;
    height: 23px;
    color: rgb(194, 194, 194);
  }


  .user-background-crop {
    width: 100%;  // Your desired width
    height: 140px;  // Your desired height
    overflow: hidden;
    position: relative;
  }
  .table td {
    padding-inline: 10px;
    padding-block: 5px;
  }


  tr td:first-child {
    border-top-left-radius: $border-radius-rounder; /* Adjust the radius as needed */
    border-bottom-left-radius: $border-radius-rounder;
  }
  
  tr td:last-child {
    border-top-right-radius: $border-radius-rounder;
    border-bottom-right-radius: $border-radius-rounder;
  }
  
  table {
    table-layout: fixed;

    th {
      padding: 0;
      padding-block: 10px;
    }

    .spacer {
      height: 15px;
    }

    tr {
        border-radius: $border-radius-rounder !important;
        padding: 20px;
        border-spacing: 15px;

      
      td {
        
        width: 100px;
        border: 0 !important;
        vertical-align: middle;
        font-size: medium;
      }

      .edit-icon {
        margin-left: 50%;
      }
  
    }


  }


  .link-item {
    background-color: rgb(27, 27, 27) !important;
    margin-top: 20px !important;
    border-radius: 10px;
    border: solid 1px $primary-brand-color;
  }

  .cropped-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;  // Ensures the width of the image matches the width of the container
    height: auto;  // Maintains aspect ratio
  }

  .profile-picture {
    left: 50%;
    padding: 0;
    position: absolute;
    transform: translate(-50%, 0%);
    z-index: 100;  // To make sure the profile picture is above the card image
    /* Set a fixed width and height for the circle */
    width: 100px;  // You can adjust the size as needed
    height: 100px; // Make sure width and height are equal for a perfect circle

    /* Make the image a circle */
    border-radius: 50%;

    /* Ensure the image covers the area and is centered, regardless of its aspect ratio */
    object-fit: cover;

    /* Optional: Add a border if desired */
    border: 2px solid white; // Adjust color and size as needed
  }

  .addlink-modal-btn {
    margin-bottom: 150px !important;
  }

  .link-section-offset {
    margin-bottom: 150px;
  }

  h5 {
    margin:0;
  }


  .edit-icon {
    color: $primary-brand-color;
    margin: 10px;
    width: 30px;
    height: auto;
  }

  tbody, h5 {
    color: white !important;
  }


  td.full-width {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  //Bootstrap tweaks
  .card {
    display: block !important; 
    padding: 0px;
    background-color: $secondary-color;
  }

  .table-bordered td, .table.table-bordered, .table thead th  { 
    border: 0px solid transparent !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $primary-brand-color;
  }


  .btn-primary {
    width: 100%;
    padding: 0px 45px 0px 45px;
  }

}

.profile-modal {
  

  .modal-dialog {
    width: 100%;
    height: 1;
    left: 0;
    margin: 0;
  }

  .modal-content {
    background-color: $secondary-color;
  }


}



.edit-profile {
  
  input {
    background-color: $secondary-color;
    border: 0;
    border-radius: $border-radius-rounder;
    color: rgb(216, 216, 216);
    padding: 8px;
    padding-left: 12px;
    width: 100%;
  }

}

.remove-link-btn {
  padding: 0 !important;
}

.position-edit-icon {
  width: 18px;
  height: 18px;
}