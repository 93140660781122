@import '../variables';


//selects navbar icons
.nav-link {
    svg {
        width: $default-icon-size !important;
        height: $default-icon-size !important;
        left: 3px;
    }
}

.nav-link {
    margin-left: 0px;
    margin-right: 0px;
    padding: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white !important;
}

.primary-color .active {
    color: $primary-brand-color !important;
}

@media (max-width: 767px) {
    .logged-footer {
      display: none;
    }
  }

