@import '../variables';
@import '../utility/mixins.scss';


//mobile styles
.scan-border {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
}



//Desktop styles

.extra-dark-grey {
    & > td {
      background-color: $extra-dark-grey !important;
    }
  }

.border-round-left {
    @include border-radius-corner('top-left', $border-radius-primary);
    @include border-radius-corner('bottom-left', $border-radius-primary);
}

.border-round-right {
    @include border-radius-corner('top-right', $border-radius-primary);
    @include border-radius-corner('bottom-right', $border-radius-primary);
}


.border-radius-primary {
    border-radius: $border-radius-primary;
}

.qr-custom {
    padding: 7px 7px 0 7px;
    cursor: pointer;
}

.link-to-profile-button {
    transition: all 0.3s ease-in-out !important;
    
}

.pointer {
    cursor: pointer;
}

