@import '../variables';
@import '../utility/mixins.scss';

.connections {
    padding: 0;
    overflow: scroll;

    .connection-main {
        padding-left: 15px;
        padding-right: 15px;
    }

    input {
        background-color: transparent;
        border-bottom: 2px solid $primary-brand-color;
        padding: 0;
        padding-left: 5px;
        padding-bottom: 1px;
        width: 100%;
        text-indent: 10px;
    }





    .topbar {
        border-bottom: 1px solid rgb(59, 59, 59);
    }

    /* Apply styles when the input gains focus */
    input:focus {
        border: 0;
        border-bottom: 3px solid $primary-brand-color;
        padding-bottom: 0px;
        border: solid 1px rgb(92, 92, 92);
    }


    .search-container {
        display: flex;
        align-items: center;
        border-radius: 10px;
      }
      
    .search-input {
        flex: 1;
        border: none;
        outline: none;
        font-size: 16px;
    }

    .contact-card {
        border-bottom: 1px solid rgb(75, 75, 75);

        h4 {
            font-weight: 750;
            font-size: 1.5rem;
            margin: 0;
        }

        .profile-pic {
            width: 70px;
        }
    }


    .letter-group {
        
        h1 {
            font-weight: 700;
            font-size: 2.5rem;
        }
    }

}


