@import '../variables';
@import '../utility/mixins.scss';



//bootstrap

.card {
    border: 0;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
}

.card-img-top {
    border-radius: 0;
}

