@mixin border-radius-corner($corner, $radius) {
  @if $corner == 'top-left' {
    border-top-left-radius: $radius;
  }
  @else if $corner == 'top-right' {
    border-top-right-radius: $radius;
  }
  @else if $corner == 'bottom-left' {
    border-bottom-left-radius: $radius;
  }
  @else if $corner == 'bottom-right' {
    border-bottom-right-radius: $radius;
  }
  @else {
    @warn "Invalid corner specified: #{$corner}";
  }
}
