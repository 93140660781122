@import '../variables';
@import '../utility/mixins.scss';


.share {

        /* Define a class for the card with a light shadow */
    .card-with-shadow {
        padding: 15px;
        //box-shadow: 0 0 15px rgba(255, 255, 255, 0.336); /* Light shadow */
    }
    


    h3 {
        color: white;
        margin-bottom: 0;
    }

    .card {
        overflow: visible;
    }

    .qr-code-canvas {
        margin: 10px;
        margin-top: 15px;
    }
}

