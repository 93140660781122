@import '../variables';
@import '../utility/mixins.scss';

.settings {
    min-height: 120vh;
    overflow: scroll !important;

    .card {
        background-color: transparent;
    }


    ul {
        color: white;

        li {
            font-size: 1.3rem;

        }

    }

    
    /* Slide out the existing settings list to the left */
    .settings-content.slide-out {
        transform: translateX(-100%);
    }
    

    
    /* Slide in the Change Password form */
    .change-password-form.slide-in {
        left: 5%; /* Adjust the right position for the final position */
      }

    label {
        font-size: 1.2rem;
    }
    
    input {
        background-color: $secondary-color;
    }


}